.MainContainerDetailed {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: grid;
    grid-template-columns: 100px auto;
}

.DetailedViewContainer {
    display: grid;
    width: 100%;
    height: 100vh;
    grid-template-columns: 400px auto;
    background-color: #1b2c50;
    overflow: auto;
}

.RobotDetails {
    width: auto;
    height: 100%;
    margin: 20px;
    background-color: #14213d;
    border-radius: 5px;
}

.ActionStatus {
    width: 100%;
    padding: 20px;
    text-align: center;
}

.ScreenTitle {
    padding-left: 25px;
}

.Screen {
    width: 315px;
    height: 177px;
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: black;
    border-radius: 15px;
    cursor: pointer;
}

.BatteryContainer {
    display: grid;
    grid-template-columns: 10% auto auto auto auto auto auto auto auto auto auto 20%;
    align-items: center;
    justify-items: center;
    padding: 15px;
}

.DisplayBatteryCharge {
    display: block;
}

.BatteryChargeNodeOff {
    display: block;
    height: 10px;
    width: 15px;
    border-radius: 4px;
    border-color: transparent;
    background-color: #d9d9d9;
}

.BatteryChargeNodeOn {
    display: block;
    height: 10px;
    width: 15px;
    border-radius: 4px;
    border-color: transparent;
    background-color: #02ff1b;
}

.BatteryText {
    justify-self: left;
    padding-left: 5px;
}

.BotStatusContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    height: 6rem;
    align-items: center;
}

.Odometer {
    width: 35%;
    display: grid;
    grid-template-rows: auto auto;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    border-radius: 10px;
    border-color: #ffba42;
}

.OdometerTextContainer {
    display: grid;
    grid-template-columns: auto auto;
}

.FocusedViewContainer {
    width: 100%;
    height: 100%;
}

.MapContainer {
    display: block;
    position: absolute;
    background-color: black;
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.mapboxgl-ctrl-logo{
    display: none !important;
}

.BotMarker {
    display: block;
    position: relative;
    height: 246.81px;
    width: 398.67px;
    background-size: contain;
    background-image: url('../../Assets/Icons/Extended Map/greenfield_weedbot.png');
    background-position: center center;
    background-repeat: no-repeat;
    text-align: center;
    color: #14213d;
    line-height: normal;
    font-size: 207.21px;
    font-weight: bold;
}

.crop-wrapper{
    overflow: hidden;
    position: relative;
    width:100%;
}

.BotMarkerContainer {
    position: relative;
    text-align: center;
}

.BotMarkerOverlay {
    padding-top: 13px;
    position: absolute;
    height: 100%;
    width: 100%;
    justify-self: center;
    color: #1b2c50;
    font-size: 12px;
}

.FocusedScreen {
    width: 0px;
    height: 0px;
    display: block;
    border-radius: 10px;
    margin-bottom: 25px;
    margin-left: 25px;
    margin-right: 25px;
    text-align: center;
}

.SmallMapContainer {
    display: block;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    background-color: black;
    height: 177px;
    width: 315px;
    border-radius: 15px;
    cursor: pointer;
}

.BotMarkerPopup {
    position: absolute;
    display: none;
    grid-template-rows: 5% auto;
    row-gap: 5px;
    border: 7px solid transparent;
    width: 250px;
    height: 170px;
    background-color: white;
    border-radius: 10px;
    text-align: center;
}

.BotMarkerIconContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    row-gap: 5px;
    border: 7px solid transparent;
    width: 250px;
    height: 150px;
    text-align: center;
    z-index: 100;
}

.SpeedUnitPopup {
    justify-self: left;
    padding-top: 10%;
}

.BotMarkerPopupBattery {
    width: 85%;
    height: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    border: 2px solid #ffba42;
    border-radius: 10px;
    cursor: pointer;
}

.MapFocusViewContainer {
    position: relative;
    display: block;
    width: 95%;
    height: 90%;
    margin-bottom: 25px;
    margin-left: 25px;
    margin-right: 25px;
}

.MiniMap {
    position: absolute;
    align-items: center;
    max-width: 200px;
    max-height: 200px;
    width: 200px;
    height: 200px;
    background-color: #14213d;
    border-radius: 10px;
    top: 10px;
    right: 10px;
}

.MiniMapMapContainer {
    display: block;
    position: absolute;
    background-color: black;
    border-radius: 10px;
    width: 190px;
    height: 190px;
    margin-left: 5px;
    margin-top: 5px;
}

.DetailedJobCompletionPercentage {
    margin: 15px;
    align-items: center;
    text-align: center;
}

.OwnershipPromptContainer {
    position: absolute;
    width: 100vw;
    height: 100vh;
}

.OwnershipPromptBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 50%;
    z-index: 100;
}

.OwnershipPrompt {
    position: absolute;
    background-color: #1b2c50;
    width: 90%;
    max-width: 1000px;
    height: auto;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    border-radius: 15px;
}

.OwnershipPromptText {
    padding: 10px;
    text-align: center;
}

.OwnershipPromptButtonContainer {
    display: grid;
    grid-template-columns: auto 150px;
    align-items: center;
}

.DetailedOwnershipPromptButtonContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
}

.DetailedOwnershipPromptButtonYes {
    width: 100px;
    height: 40px;
    justify-self: right;
    border-radius: 10px;
    margin: 15px;
    border: transparent;
    background-color: #ffba42;
    opacity: 0.8;
    transition: 0.2s;
    cursor: pointer;
}

.DetailedOwnershipPromptButtonYes:disabled {
    opacity: 0.5;
    cursor: default;
    background-color: grey;
}

.DetailedOwnershipPromptButtonYes:hover:enabled {
    opacity: 1;
}

.DetailedOwnershipPromptButtonNo {
    width: 100px;
    height: 40px;
    justify-self: left;
    border-radius: 10px;
    margin: 15px;
    border: 3px #ffba42 solid;
    background-color: transparent;
    opacity: 0.8;
    transition: 0.2s;
    cursor: pointer;
}

.DetailedOwnershipPromptButtonNo:hover {
    opacity: 1;
}

.StateSelectContainer {
    display: grid;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    grid-template-columns: auto auto auto auto auto auto auto auto;
    justify-items: center;
}

.ComponentStateButtonOn, .ComponentStateButtonOff {
    width: 85%;
    height: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1rem;
    cursor: pointer;
}

.ComponentStateButtonOn {
    background-color: #ffba42;
    border-color: transparent;
}

.ComponentStateButtonOff {
    background-color: transparent;
    border: 2px solid #ffba42;
}

.BotControlsContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    align-items: center;
    height: 16rem;
}

.ComponentStatusAndControl {
    display: grid;
    height: 100%;
    grid-template-rows: 50% 50%;
}

.ComponentControlText {
    font-size: 14;
    color: white;
}

.ComponentControlButton {
    width: 85%;
    height: 85%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1rem;
    cursor: pointer;   
    background-color: #ffba42;
    border-color: #ffdca0;
}