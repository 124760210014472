.RobotCards {
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 100%;
}

.CardBackground {
  background-color: #14213d;
  border: 2px solid transparent;
  border-radius: 1rem;
  cursor: pointer;
  height: 25rem; 
  width: 14rem; 
  margin: 0.1rem;
  display: 'flex'; 
  flex-direction: 'column';
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.CardBackground:hover {
  border: 2px solid #ffba42;
}

@keyframes FlashRed {
  0% {background-color: #14213d}
  50% {background-color: #ff0000}
  100% {background-color: #14213d}
}

@keyframes FlashOrange {
  0% {background-color: #14213d}
  50% {background-color: #ffba42}
  100% {background-color: #14213d}
}

@keyframes FlashYellow {
  0% {background-color: #14213d}
  50% {background-color: #fff44f}
  100% {background-color: #14213d}
}

@keyframes FlashBrown {
  0% {background-color: #14213d}
  50% {background-color: #6E260E}
  100% {background-color: #14213d}
}

@keyframes FlashBlack {
  0% {background-color: #14213d}
  50% {background-color: #000000}
  100% {background-color: #14213d}
}

.CardHeader {
  display: grid;
  grid-template-columns: 15% 45% 40%;
  height: 5%;
  padding: 5px;
}

.StatusIcon {
  margin-top: 5px;
  margin-left: 5px;
  height: 20px;
  width: 20px;
}

.Name {
  padding-top: 3%;
}

.BatteryAndWifi {
  display: grid;
  justify-items: right;
  align-items: center;
  padding-right: 15px;
  grid-template-columns: 35% 15% 35% 15%;
  gap: 5px;
}

.MaskGroup {
  height: 15px;
  width: 15px;
}

.KeyStatusContainer {
  background-color: #14213d;
  border-radius: 1rem;
}