.MainContainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 100px auto;
}

.ContentContainer {
  height: 100vh;
  width: 100%;
  overflow-x: auto;
  background-color: #1b2c50;
}

.OverallViewContentsContainer {
  display: grid;
  grid-template-columns: auto 50%;
  width: 100%;
  height: 100%;
}

.OverallViewContentsContainerDualMonitor {
  width: 100%;
  height: 100%;
}

.RobotCardsFilteringMenuContainer {
  display: grid;
  grid-template-rows: 100px auto;
  border-right: 1px solid white;
  height: 100vh;
  max-height: 100%;
  padding-top: 90px;
  gap: 1rem;
}

.OverallMapContainer {
  display: grid;
  grid-template-rows: 180px 50px auto 100px;
  height: 100%;
  min-width: 550px;
  max-width: 50vw;
  padding-top: 90px;
}

.PauseAllRobotsContainer {
  display: block;
  width: 100%;
  height: 100px;
}

.RobotCardsTopBar {
  display: grid;
  grid-template-columns: auto auto auto;
}

.BotClaimTableContainer {
  overflow-x: auto;
}

.BotClaimTable {
  width: 1000px;
}

.BotClaimTableHeader {
  display: grid;
  grid-template-columns: 50px 175px 510px 200px;
  margin: 15px;
  gap: 15px;
  background-color: #14213d;
  align-items: center;
}

.BotClaimTableRow {
  display: grid;
  grid-template-columns: 50px 175px 510px 30%;
  gap: 15px;
  margin-bottom: 15px;
  align-items: center;
}

.BotClaimTableRowContainer {
  max-height: 500px;
  background-color: #1b2c50;
  overflow-y: auto;
  margin: 15px;
}

.InitialBotClaimPrompt {
  position: absolute;
  background-color: #1b2c50;
  width: 50%;
  max-width: 80%;
  height: 80%;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  border-radius: 15px;
  display: grid;
  grid-template-rows: 10% 10% 70% 10%;
}

.InitialBotClaimTable {
  width: 100%;
  height: 70%;
}

.InitialBotClaimTableHeader {
  display: grid;
  grid-template-columns: 5% 45% 20% 20%;
  margin: 1rem;
  gap: 1rem;
  background-color: #14213d;
  align-items: center;
}

.BotClaimTableSortOption {
  cursor: pointer;
  display: inline;
  gap: 5%;
  align-items: center;
  justify-items: center;
}

.InitialBotClaimTableRowContainer {
  max-height: 100%;
  background-color: #1b2c50;
  overflow-y: auto;
  margin: 15px;
}

.InitialBotClaimTableRow {
  display: grid;
  grid-template-columns: 5% 50% 25% 10%;
  gap: 15px;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
}

.InitialOwnershipPromptButtonContainer {
  display: grid;
  grid-template-columns: auto;
  align-items: right;
}

.InitialOwnershipPromptButtonYes {
  width: 30%;
  height: 80%;
  justify-self: right;
  margin-right: 15px;
  border-radius: 10px;
  border: transparent;
  background-color: #ffba42;
  opacity: 0.8;
  transition: 0.2s;
  cursor: pointer;
}

.InitialOwnershipPromptButtonYes:disabled {
  opacity: 0.5;
  cursor: default;
  background-color: grey;
}

.InitialOwnershipPromptButtonYes:hover:enabled {
  opacity: 1;
}

.TableText, .TableTextOffline {
  justify-self: left;
  margin-top: auto;
  margin-bottom: auto;
}

.TableTextOffline {
  opacity: 50%;
}

.OwnershipPromptInstructionText {
  padding-left: 15px;
}

.CardSettingsContainer {
  display: grid;
  grid-template-rows: auto auto;
}

.DualMonitorSwitchContainer {
  display: grid;
  grid-template-columns: 160px auto;
  align-items: left;
  align-content: left;
}

.BatchSettingsButton {
  display: block;
  border: solid transparent;
  border-radius: 10px;
  background-color: #ffba42;
  opacity: 0.8;
  height: 3rem;
  width: 12rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 2rem;
  transition: 0.2s;
  cursor: pointer;
}

.BatchSettingsButton:disabled {
  grid-template-columns: 180px auto auto;
}

.SetYamlButtonContainer {
  display: grid;
  grid-template-columns: 170px auto;
  height: 100px;
  width: 100%;
  justify-items: start;
  padding-left: 30px;
}

.SetYamlButton {
  display: block;
  height: 45px;
  width: 150px;
  border: transparent solid 1px;
  border-radius: 10px;
  background-color: #ffba42;
  justify-self: start;
  padding: 5px;
  cursor: pointer;
  opacity: 0.9;
}

.SetYamlButton:hover:enabled {
  opacity: 1.0;
}

.SetYamlButton:disabled {
  background-color: grey;
  opacity: 0.5;
  cursor: default;
}

.NewJobButton {
  display: block;
  height: 45px;
  width: 150px;
  border: transparent solid 1px;
  border-radius: 10px;
  background-color: #ffba42;
  justify-self: start;
  padding: 5px;
  cursor: pointer;
  opacity: 0.9;
}

.NewJobButton:hover:enabled {
  opacity: 1.0;
}

.NewJobButton:disabled {
  background-color: grey;
  opacity: 0.5;
  cursor: default;
}

.BatchSettingsButton:hover:enabled, .ChangeSelectionButton:hover:enabled {
  opacity: 1;
}

.StateList {
  display: grid;
  grid-template-columns: 55px 30px 25px 50px 50px 60px 60px 110px;
  gap: 10px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.SaveBatchSettingsSpinner{
  margin: auto;
  width: 25px;
  height: 25px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #383636; /* Black */
  border-radius: 50%;
  align-self: center;
  justify-self: center;
  animation: spinner 1s linear infinite;
}

.OwnershipPromptButtonYes {
  width: 100px;
  height: 40px;
  justify-self: center;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
  border: transparent;
  background-color: #ffba42;
  opacity: 0.8;
  transition: 0.2s;
  cursor: pointer;
}

.OwnershipPromptButtonYes:disabled {
  opacity: 0.5;
  cursor: default;
  background-color: grey;
}

.OwnershipPromptButtonYes:hover:enabled {
  opacity: 1;
}

.OwnershipPromptButtonNo {
  width: 100px;
  height: 40px;
  justify-self: right;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
  border: 3px #ffba42 solid;
  background-color: transparent;
  opacity: 0.8;
  transition: 0.2s;
  cursor: pointer;
}

.OwnershipPromptButtonNo:hover {
  opacity: 1;
}

.ChangeSelectionButton {
  display: block;
  background-color: #C2D8FF;
  border: solid transparent;
  border-radius: 10px;
  opacity: 0.8;
  height: 3rem;
  width: 18rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 2rem;
  cursor: pointer;
}
