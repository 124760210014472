.OverallMap {
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  height: 100%;
}
.map-container {
  display: block;
  background-color: black;
  border-radius: 10px;
  width: 95%;
  height: 90%;
  margin-bottom: 25px;
  margin-left: 25px;
  margin-right: 25px;
}
.mapboxgl-ctrl-logo{
  display: none !important;
}

.OverAllBotMarker {
  display: block;
  position: absolute;
  background-size: cover;
  background-image: url('../../Assets/Icons/Extended Map/greenfield_weedbot.png');
  background-position: center center;
  text-align: center;
  font-weight: bold;
  color: #14213d;
  line-height: normal;
}

.mapboxgl-popup-content {
  color: white;
  background-color: red;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: red;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: red;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: red;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: red;
}