.FieldRegionButtons {
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.FieldRegionButtonsContainer {
  display: grid;
  width: 100%;
  grid-template-columns: 50% 50%;
}