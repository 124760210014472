.OverallMapContainerDualMonitor {
  display: grid;
  grid-template-rows: 180px 50px auto 100px;
  height: 100vh;
  width: 100vw;
  padding-top: 90px;
  background-color: #1b2c50;
}

.PauseAllRobotsContainer {
  display: block;
  width: 100%;
  height: 100px;
}