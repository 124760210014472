.Header {
  position: absolute;
  display: grid;
  padding-left: 100px;
  grid-template-columns: auto 90px 90px 90px;
  width: 100%;
  height: 90px;
  background-color: #14213d;
  border-bottom: 1px solid white;
  align-items: center;
  z-index: 98;
}

.HeaderIcon, .HeaderIconDeactivated {
  display: block;
  height: auto;
  margin: auto;
}

.HeaderIcon {
  cursor: "pointer";
}

.HeaderDetailed {
  display: grid;
  grid-template-columns: 125px auto 90px 90px 90px;
  width: 100%;
  height: 90px;
  background-color: #14213d;
  border-bottom: 1px solid white;
  align-items: center;
}

.VersionRow {
  display: flex;
  border-color: black;
  border-style: solid;
  padding: 0.1rem;
  background-color: black;
}

.VersionPopoverContent {
  gap: 5px;
}

.VersionLabel, .CurrentVersionText, .DesiredVersionText {
  display: inline;
}

.DesiredVersionText {
  color: coral;
  opacity: 75%;
}

.MuiPopover-paper {
  border-style: solid;
  border-color:  black;
}