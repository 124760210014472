.SideBar {
  display: grid;
  grid-template-rows: 90% 10%;
  width: 100%;
  height: 100vh;
  background-color: #14213d;
  border-right: 1px solid white;
  z-index: 99;
}

.MainLogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 125px;
}

.LogoutButton {
  display: block;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}