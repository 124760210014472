.PauseRobotsButton {
  display: flex;
  padding: 2px;
  justify-content: center;
  align-items: center;
  background-color: #bc0000;
  border: solid 1px transparent;
  border-radius: 10px;
  width: 350px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.9;
  transition: 0.2s;
  cursor: pointer;
}

.PauseRobotsButton:disabled {
  opacity: 0.5;
  background-color: grey;
  cursor: default;
}

.PauseRobotsButton:hover:enabled {
  opacity: 1;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.PauseRobotsButtonSpinner{
  margin: auto;
  width: 35px;
  height: 35px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #383636; /* Black */
  border-radius: 50%;
  align-self: center;
  justify-self: center;
  animation: spinner 1s linear infinite;
}