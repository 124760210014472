.ComponentStateIconOn, .ComponentStateIconOff {
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.5rem;
  justify-items: center;
  margin-bottom: 0.5rem;
  align-items: center;
  cursor: pointer;
  background-color: #ffba42;
  text-shadow: 0.1rem 0.1rem 0.5rem black;
  border-color: #ffdca0;
}

.ComponentStateIconOff {
  background-color: transparent;
  border-color: #ffba42;
}

.ComponentStateText {
  background-color: black;
  color: white;
}