.EventCardDefault, .EventCardInsTemperatureTooHigh, .EventCardPathErrorTooLarge, .EventCardCrossTrackTooLarge, .EventCardFeelerFailure {
  background-color: #1b2c50;
  border-color: #808080;
  border-style: solid;
  border-width: thin;
  display: grid;
  grid-template-columns: 5% auto 10%;
  border-left-width: thick;
  border-left-color: green;
}

.EventCardInsTemperatureTooHigh {
  border-left-color: red;
}

.EventCardPathErrorTooLarge {
  border-left-color: yellow;
}

.EventCardCrossTrackTooLarge {
  border-left-color: #6E260E;
}

.EventCardFeelerFailure {
  border-left-color: #000000;
}

.EventTextAcknowledged {
  opacity: 50%;
}